@import "floatingLabel";

html, body, #root {
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
    height: 100% !important;

    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: calc(10px + 1.3vmin);
}

#root {
    display: flex;
    flex-direction: column;
}
  
h1 {
    font-size: 150%;
    font-weight: bold;
}

/* 
    CS: added debug class for instant recognitiion of debug outputs on screen
    and to enable/disable the all at once if necessary
 */
.apo-debug{
    font-size: .5rem;
    font-weight: 500;
    color: #333;
    border: 2px solid rgba(212, 66, 66, 0.815);;
    border-radius: .4em;
    padding: .2em 1.5em;
    display: flexbox;
    justify-content: center;
    align-items: center;
    margin: 0 .5em;
    animation:ripple 1s forwards
}

/*
    CS: 27 July 2021
    Implemented new Linda logo into navigation bar
*/
.navbar{position: relative;}
.linda-logo{
    position: absolute;
    left:50%;
    top: 8px;
    transform: translateX(-50%);
    height:70px;
    width:auto;
}
@media screen and (max-width: 576px) {
    .navbar{margin-bottom: 2em;}
    .navbar-nav{margin-top: 3em;}
    /* .apo-content .container{} */
    .linda-logo{
        left:50%;
        top: 55px;
        height:50px;
        width:auto;
    }
}


@keyframes ripple{
    0%{
        opacity:1;
        -webkit-transform:scale(0);
        transform:scale(5)
    }
    100%{
        opacity:1;
        -webkit-transform:scale(2);
        transform:scale(1)
    }
}


.apo-loading {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.app-content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.apo-content {
    flex: 1;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.apo-link-secondary {
    color:#525f7f;
    &:hover {
        color:black;
    }
}

.apo-link {
    text-decoration: none;
    &:hover {
        color:black;
        text-decoration: underline;
    }
}

.noselect {
    -moz-user-select: none;
   -khtml-user-select: none;
   -webkit-user-select: none;
   -ms-user-select: none;
   user-select: none;
}

.shadow-readonly {
    background-color: #fff !important;
}

.rc-slider-handle {
    border-color: rgb(45, 206, 137) !important;
    &:active, &:focus {
        box-shadow: 0 0 5px rgb(45, 206, 137) !important;
    }
}

.rc-slider-track {
    background-color: rgb(45, 206, 137) !important;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    border-color: rgb(45, 206, 137) !important;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    border-color:  rgb(45, 206, 137) !important;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: rgb(45, 206, 137) !important;
}
.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    background-color: rgb(45, 206, 137) !important;
    box-shadow: 0 3px 2px rgba(233,236,239,.05);
}
.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: darken(rgb(45, 206, 137), 10%)  !important;
    background-color: darken(rgb(45, 206, 137), 10%)  !important;
}

.dropdown-menu {
    max-height: 13rem;
    overflow-y: auto;
}

  .display-block {
      display: block !important;
  }

.is-valid {
    /* 
        CS: 27/5/20 changed from light green to black
        color: #2dce89;
    */
    color: #333;
}

.is-invalid {
    /* 
        CS: 27/5/20 changed light orange to black
        color: #fb6340; 
    */
    color: #333;
}

.upload {
    border-radius: 5px;
    border: 2.5px dashed #32325d;
}

.custom-toggle {
    height: 24px !important;
}

.custom-toggle input:checked+.custom-toggle-slider {
    border: 1px solid rgb(45, 206, 137) !important;
}

.custom-toggle input:checked+.custom-toggle-slider:after {
    right: auto;
    left: 0;
    content: attr(data-label-on);
    color: rgb(45, 206, 137) !important;
}

.custom-toggle input:checked+.custom-toggle-slider:before {
    transform: translateX(28px);
    background: rgb(45, 206, 137) !important;
}

.print {
    background-color: #f8f9fa;
}

.custom-control-label {
    height: auto !important;
}
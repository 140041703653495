:root {
    --input-padding-x: .75rem;
    --input-padding-y: .8125rem;
}

.form-label-group {
    text-align: initial;
    position: relative;
  }
  
  .form-label-group > input,
  .form-label-group > label {
    padding: var(--input-padding-y) var(--input-padding-x);
  }
  
  .form-label-group > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0; /* Override default `<label>` margin */
    font-size: .875rem;
    line-height: 1.5;
    color: #8898aa;
    border: 0;
    border-radius: .25rem;
    transition: all .1s ease-in-out;
  }
  
  .form-label-group input::-webkit-input-placeholder {
    color: transparent !important;
  }
  
  .form-label-group input:-ms-input-placeholder {
    color: transparent !important;
  }
  
  .form-label-group input::-ms-input-placeholder {
    color: transparent !important;
  }
  
  .form-label-group input::-moz-placeholder {
    color: transparent !important;
  }
  
  .form-label-group input::placeholder {
    color: transparent !important;
  }
  
  .form-label-group input:not(:placeholder-shown) {
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(var(--input-padding-y) / 3);
  }
  
  .form-label-group input:not(:placeholder-shown) ~ label {
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: .65rem;
    color: #8898aa;
  }